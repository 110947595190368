import { useTranslation } from 'react-i18next';

const SecondaryButton = (props) => {
    const { t } = useTranslation();
    return ( 
        <button
            style={props.style}
            onClick={props.handleFunc}
        >
            {t(props.buttonText)}
        </button>
    )
}
export default SecondaryButton;