import React from 'react';
import '../css/Dice.css';
import pic1 from '../images/positions/cowgirl-last-longer_1024x1024.webp';
import pic2 from '../images/positions/grinding-missionary-last-longer_1024x1024.webp';
import pic3 from '../images/positions/lazy-dog-sex-position_1024x1024.webp';
import pic4 from '../images/positions/perpendicular-sex-position_1024x1024.webp';
import pic5 from '../images/positions/Spooning-sex-position_1024x1024.webp';
import pic6 from '../images/positions/the-throne-sex-position_1024x1024.webp';
import place1 from '../images/placesToFuck/livingroom.png';
import place2 from '../images/placesToFuck/car.png';
import place3 from '../images/placesToFuck/garden.png';
import place4 from '../images/placesToFuck/bedroom.png';
import place5 from '../images/placesToFuck/kitchen.png';
import place6 from '../images/placesToFuck/bath.png';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const Dice = () => {
    const { t } = useTranslation();
    const positionsList = [pic1, pic2, pic3, pic4, pic5,pic6];
    const placesList = [place1,place2,place3,place4,place5,place6];
    const [whichList, setWhichList] = useState(positionsList);
    const [whichDice, setWhichDice] = useState(true);
    const [changePic, setChangePic] = useState(whichList[0]);
    const [animation, setAnimation] = useState(false);
    useEffect(() => {
        if (whichDice) {
            setWhichList(positionsList);
        } else {
            setWhichList(placesList);
        }
    }, [whichDice]);

    useEffect(()=> {
        setChangePic(whichList[0])
    }, [whichList]);

    const handleDice = (event) => {
        event.target.disabled = true;
        setAnimation(true);
        setTimeout(function () {
            setChangePic(whichList[1]);
        }, 1000);
        setTimeout(function () {
            setChangePic(whichList[2]);
        }, 1600);
        setTimeout(function () {
            setChangePic(whichList[3]);
        }, 2200);
        setTimeout(function () {
            setChangePic(whichList[4]);
        }, 2800);
        setTimeout(function () {
            setChangePic(whichList[5]);
        }, 3400);
        setTimeout(function () {
            setChangePic(getRandomItem(whichList));
        }, 4000);
        setTimeout(function () {
            setAnimation(false);
            event.target.disabled = false;
        }, 5000);
    };

    function getRandomItem(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);

        const item = arr[randomIndex];

        return item;
    }
    return (
        <>
            
            <div className="dicePage d-flex gap-5 justify-content-center align-items-center flex-column">
            <h1 className='text-center'>{t("diceH")}</h1>
                <div className="diceButtons">
                    <button style={{color:'black'}} className="button" onClick={() => setWhichDice(true)}>
                        {t('positionDice')}
                    </button>
                    <button style={{color:'black'}} className="button" onClick={() => setWhichDice(false)}>
                        {t('placeDice')}
                    </button>
                </div>
                {
                    <div className="dices">
                        <div
                            className={` d-flex justify-content-center align-items-center ${
                                animation ? 'diceAnimation ' : 'test'
                            }`}
                        >
                            <img src={changePic} width="300" alt="kamasutra"></img>
                        </div>
                    </div>
                }
                <button className="button" onClick={event => handleDice(event)}>
                    {t('play')}
                </button>
            </div>
        </>
    );
};

export default Dice;
