import { useEffect, useState } from 'react';
import Hot from './pages/Hot';
import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import i18n from './i18n';
import Dice from './pages/Dice';

import { HashRouter, Routes, Route } from 'react-router-dom';
import Card from './pages/Card';
import Blog from './pages/Blog';
import Never from './pages/Never';
import Modall from './components/Modall';
import Error from './pages/Error';
import About from './pages/about';
import Contact from './pages/contact';
import Layout from './components/common/Layout';
import Home from './pages/Home';
library.add(fas);
function App() {
    const [modalShow, setModalShow] = useState(false);
    const isBiggerThanEighteen = localStorage.getItem('isBiggerThan18');

    // useEffect(() => {
    //     if(!isBiggerThanEighteen){
    //         setModalShow(true);
    //     }
    // }, [isBiggerThanEighteen]);
    return (
        <>
            <Modall show={modalShow} onHide={() => setModalShow(false)} />
            <HashRouter>
                <Layout>
                    <Routes>
                        <>
                            <Route path="/hot" element={<Hot />} />
                            <Route path="/dice" element={<Dice />} />
                            <Route path="/card" element={<Card />} />
                            <Route path="/never" element={<Never />} />
                            <Route path="/blog" element={<Blog />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/" element={<Home />} />
                            <Route path="*" element={<Error />} />
                        </>
                    </Routes>
                </Layout>
            </HashRouter>
        </>
    );
}

export default App;
