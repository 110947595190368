// src/components/Blog.jsx
import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import BlogCard from './BlogCard';
import BlogDetail from './BlogDetail';
import { useTranslation } from 'react-i18next';
import chattingPic from '../../images/blogPics/chatting.jpg';
import giftPic from '../../images/blogPics/gift.jpg';

const Blog = () => {
    const { t } = useTranslation();
    const [selectedPost, setSelectedPost] = useState(null);
    const blogPosts = [
        {
            id: 1,
            title: t('blogPost1.title'),
            excerpt: t('blogPost1.excerpt'),
            content: [
                t('blogPost1.q1'),
                t('blogPost1.q2'),
                t('blogPost1.q3'),
                t('blogPost1.q4'),
                t('blogPost1.q5'),
                t('blogPost1.q6'),
                t('blogPost1.q7'),
                t('blogPost1.q8'),
                t('blogPost1.q9'),
                t('blogPost1.q10'),
                t('blogPost1.q11'),
                t('blogPost1.q12'),
                t('blogPost1.q13'),
                t('blogPost1.q14'),
                t('blogPost1.q15'),
                t('blogPost1.q16'),
                t('blogPost1.q17'),
                t('blogPost1.q18'),
                t('blogPost1.q19'),
                t('blogPost1.q20')
            ],
            image: chattingPic
        },
        {
            id: 1,
            title: t('blogPost2.title'),
            excerpt: t('blogPost2.excerpt'),
            content: [
                t('blogPost2.q1'),
                t('blogPost2.q2'),
                t('blogPost2.q3'),
                t('blogPost2.q4'),
                t('blogPost2.q5'),
                t('blogPost2.q6'),
                t('blogPost2.q7'),
                t('blogPost2.q8'),
                t('blogPost2.q9'),
                t('blogPost2.q10'),
                t('blogPost2.q11'),
                t('blogPost2.q12'),
                t('blogPost2.q13'),
                t('blogPost2.q14'),
                t('blogPost2.q15'),
                t('blogPost2.q16'),
                t('blogPost2.q17'),
                t('blogPost2.q18'),
                t('blogPost2.q19'),
                t('blogPost2.q20')
            ],
            image: giftPic
        }
    ];
    return (
        <Container className="my-5">
            {selectedPost ? (
                <>
                    <BlogDetail
                        title={selectedPost.title}
                        content={selectedPost.content}
                        image={selectedPost.image}
                        onBack={() => setSelectedPost(null)}
                    />
                    <Button
                        style={{
                            backgroundColor: 'rgb(255, 222, 243)',
                            color: 'black',
                            borderColor: 'black'
                        }}
                        onClick={() => setSelectedPost(null)}
                        className="mt-4"
                    >
                        Back to Blog List
                    </Button>
                </>
            ) : (
                <Row className="m-2">
                    {blogPosts.map((post) => (
                        <Col key={post.id} md={4}>
                            <BlogCard
                                title={post.title}
                                excerpt={
                                    Array.isArray(post.content)
                                        ? post.content[0].slice(0, 30) + '...'
                                        : post.content.slice(0, 120) + '...'
                                }
                                image={post.image}
                                onClick={() => setSelectedPost(post)}
                            />
                        </Col>
                    ))}
                </Row>
            )}
        </Container>
    );
};

export default Blog;
