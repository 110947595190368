import { useState, useEffect, useRef } from 'react';
import trFlat from '../../images/indir.jpeg';
import logo from '../../images/logoss.png';
import enFlat from '../../images/indir (1).jpeg';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import grFlat from '../../images/flags/german.webp';
import esFlat from '../../images/flags/espanol.svg';
import itFlat from '../../images/flags/italy.png';
import plFlat from '../../images/flags/poland.webp';
import inFlat from '../../images/flags/india.png';
// import ruFlat from '../images/indir (3).jpeg';
import frFlat from '../../images/flags/french.webp';
// import abFlat from '../images/indir (5).jpeg';
const Header = () => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const dropdownRef = useRef(null);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const [openSidebar, setOpenSidebar] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (openSidebar && ref.current && !ref.current.contains(e.target)) {
                setOpenSidebar(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [openSidebar]);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (dropdownOpen && ref.current && !ref.current.contains(e.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [dropdownOpen]);
    const [locale, setLocale] = useState('en');
    const localLanguage = localStorage.getItem('lng');

    useEffect(() => {
        if (localLanguage !== null) {
            setLocale(localLanguage);
            changeLanguage(localLanguage);
        }
    }, [localLanguage]);
    const toggle = () => {
        setDropdownOpen((prev) => !prev);
    };
    const changeFlag = (lng) => {
        setLocale(lng);
    };
    const onClick = (event) => {
        localStorage.setItem('lng', event.currentTarget.name);
        changeLanguage(event.currentTarget.name);
        changeFlag(event.currentTarget.name);
    };
    const languages = {
        en: {
            flag: enFlat
        },
        tr: {
            flag: trFlat
        },
        fr: {
            flag: frFlat
        },
        gr: {
            flag: grFlat
        },
        es: {
            flag: esFlat
        },
        in: {
            flag: inFlat
        },
        pl: {
            flag: plFlat
        },
        it: {
            flag: itFlat
        }
        // ru: {
        //     flag: ruFlat
        // },
        // ab: {
        //     flag: abFlat
        // }
    };
    return (
        <header>
            <nav className="container d-flex justify-content-between align-items-center">
                <div className="flex gap-3 justify-center align-center">
                    <Link to="/">
                        <img width="85" height="85" src={logo} alt="logo"></img>
                    </Link>
                </div>
                <ul className="gap-5 headerLinks">
                    <li>
                        <Link to="/dice">{t('dicePage')}</Link>
                    </li>
                    <li>
                        <Link to="/card">{t('cardPage')}</Link>
                    </li>
                    <li>
                        <Link to="/hot">{t('hotPage')}</Link>
                    </li>
                    <li>
                        <Link to="/never">{t('neverPage')}</Link>
                    </li>
                    <li>
                        <Link to="/blog">Blog</Link>
                    </li>
                </ul>
                <div className="d-flex align-items-center justify-content-center">
                    <Dropdown
                        style={{ backgroundColor: 'none !important' }}
                        isOpen={dropdownOpen}
                        toggle={toggle}
                    >
                        <DropdownToggle
                            style={{ backgroundColor: 'none !important' }}
                            className="dropdownLng"
                        >
                            <img
                                style={{ marginRight: '0.5rem' }}
                                src={languages[locale]?.flag}
                                alt="Flag"
                                width={33}
                            />
                        </DropdownToggle>
                        <DropdownMenu ref={dropdownRef} style={{ backgroundColor: 'white' }}>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="tr"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="tr-flag" width="25" name="tr" src={trFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="en"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="en-flag" name="en" width="25" src={enFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="fr"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="fr-flag" width="25" name="fr" src={frFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="gr"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="gr-flag" width="25" name="gr" src={grFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="es"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="es-flag" width="25" name="es" src={esFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="in"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="in-flag" width="25" name="in" src={inFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="pl"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="pl-flag" width="25" name="pl" src={plFlat}></img>{' '}
                            </DropdownItem>
                            <DropdownItem
                                className="dropdownLngItem"
                                name="it"
                                onClick={(event) => {
                                    onClick(event);
                                }}
                            >
                                <img alt="it-flag" width="25" name="it" src={itFlat}></img>{' '}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <AiOutlineMenu
                        className="menuButton"
                        onClick={() => setOpenSidebar((prev) => !prev)}
                        type="button"
                        style={{ fontSize: '2rem', color: 'white' }}
                    />
                </div>
                {openSidebar && <Sidebar setOpenSidebar={setOpenSidebar} refProp={ref} />}
            </nav>
        </header>
    );
};
export default Header;
