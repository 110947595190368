import { FiInstagram } from 'react-icons/fi';
import { BsTwitter } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PrivacyModal from '../PrivacyModal';
import TermsModal from '../TermsModal';
const Footer = () => {
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    var today = new Date();
    var yyyy = today.getFullYear();
    return (
        <footer className="d-flex flex-column  align-items-center gap-2">
            <PrivacyModal
                title="Privacy Policy"
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <TermsModal
                title="Terms and Conditions"
                show={modalShow1}
                onHide={() => setModalShow1(false)}
            />
            <div
                style={{ padding: '2rem 0rem' }}
                className="d-flex flex-column  align-items-center gap-4"
            >
                <ul className="d-flex align-items-center gap-3">
                    <li>
                        <p style={{cursor:'pointer'}} onClick={() => setModalShow(true)}>
                            {t('privacy')}
                        </p>
                    </li>
                    <li>
                        <p style={{cursor:'pointer'}} onClick={() => setModalShow1(true)}>
                            {t('terms')}
                        </p>
                    </li>
                    <li>
                        <Link to="/about">
                            {t('about')}
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact">
                            {t('contact')}
                        </Link>
                    </li>
                </ul>
                <ul className="d-flex align-items-center gap-3">
                    <li>
                        <a target='_blank' href="https://www.instagram.com/kamadicecom">
                            <FiInstagram style={{ fontSize: '1.5rem' }} />
                        </a>
                    </li>
                    <li>
                        <a target='_blank' href="https://www.twitter.com/kamadicecom">
                            <BsTwitter style={{ fontSize: '1.5rem' }} />
                        </a>
                    </li>
                    <li>
                        <a target='_blank' href="mailto:kamadice@gmail.com">
                            <HiOutlineMail style={{ fontSize: '1.5rem' }} />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="copyright">
                <p style={{ color:'black' }}>
                    {t('copyright')} © {yyyy} . {t('rights')}
                </p>
            </div>
        </footer>
    );
};
export default Footer;
