import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SecondaryButton from '../components/common/SecondaryButton';
const taskArray = [
    "hot-1",
"hot-2",
"hot-3",
"hot-4",
"hot-5",
"hot-6",
"hot-7",
"hot-8",
"hot-9",
"hot-10",
"hot-11",
"hot-12",
"hot-13",
"hot-14",
"hot-15",
"hot-16",
"hot-17",
"hot-18",
"hot-19",
"hot-20",
"hot-21",
"hot-22",
"hot-23",
"hot-24",
"hot-25",
"hot-26",
"hot-27",
"hot-28",
"hot-29",
"hot-30",
"hot-31",
"hot-32",
"hot-33",
"hot-34",
"hot-35",
"hot-36",
"hot-37",
"hot-38",
];
const Hot = () => {
    const { t } = useTranslation();
    function getRandomItem(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);

        const item = arr[randomIndex];

        return item;
    }
    const [openDiv, setOpenDiv] = useState(false);
    const [randomItem, setRandomItem] = useState();
    const handleClick = () => {
        const item = setRandomItem(getRandomItem(taskArray));
        taskArray.splice(taskArray.indexOf(item))
        setOpenDiv(true)
    };
    return (
        <>
            <div className="container d-flex flex-column justify-content-center gap-3">
            <h1 className='text-center mt-4 pt-1'>{t("hotH")}</h1>
                <div style={{float:'right',marginTop:'1rem'}}>
                    <SecondaryButton style={{
                width: '25rem',
                padding: '1rem 2rem',
                border: 'none',
                color:'#35212A',
                float:'right', 
                backgroundColor: 'rgb(255, 222, 243)'
         }} handleFunc={handleClick} buttonText='randomSexChallangeButton' />
                </div>
                {openDiv && (
                    <div
                    style={{
                        border: '3px solid #FFDEF3',  
                        color: 'white',
                        padding: '1.5rem 0rem'
                    }}
                >
                    <p style={{ marginLeft: '2rem', color:'black' }}>{t(randomItem)}</p>
                </div>
                )}
                <ul className="d-flex flex-column gap-1">
                    {taskArray.map((item, idx) => (
                        <li
                            style={{
                                boxShadow: '2px 0px 1px #120651',
                                padding: '1rem 0rem'
                            }}
                            key={idx}
                        >
                            <p style={{ marginLeft: '2rem' }}>{idx + 1} {t(item)}</p>
                        </li>
                    ))}
                </ul>
                <div></div>
            </div>
        </>
    );
};
export default Hot;
