import Header from './Header';
import { Analytics } from '@vercel/analytics/react';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
        <div style={{ minHeight: '923px' }} className="d-flex flex-column justify-content-between">
            <Header />
            {children}
            <Footer />
            <Analytics />
        </div>
    );
};
export default Layout;
