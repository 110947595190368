import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SecondaryButton from '../components/common/SecondaryButton';
import Dropdown from 'react-bootstrap/Dropdown';


const Never = () => {
    const { t } = useTranslation();
    function getRandomItem(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);

        const item = arr[randomIndex];

        return item;
    }
    const [openDiv, setOpenDiv] = useState(false);
    const [randomItem, setRandomItem] = useState();
    const [sexQA, setSexQA] = useState(true);
    const [friendlyQA, setFriendlyQA] = useState(false);
    const [bothQA, setbothQA] = useState(false);
    const [questionType, setQuestionType] = useState(t('playwith18'));
    const handleSexQA = () => {
        setFriendlyQA(false);
        setbothQA(false);
        setQuestionType(t('playwith18'))
        setSexQA(true);
    };
    const handleFriendlyQA = () => {
        setSexQA(false);
        setbothQA(false);
        setQuestionType(t('playWFriends'))
        setFriendlyQA(true);
    };
    const handleBothQA = () => {
        setSexQA(false);
        setFriendlyQA(false);
        setQuestionType(t('both'))
        setbothQA(true);
    };
    const handleClick = () => {
        if (sexQA) {
            const item = setRandomItem(getRandomItem(hotq));
            hotq.splice(hotq.indexOf(item))
        } else if (bothQA) {
            const item = setRandomItem(getRandomItem(bothq));
            bothq.splice(bothq.indexOf(item))
        } else {
            const item = setRandomItem(getRandomItem(friendlyq));
            friendlyq.splice(friendlyq.indexOf(item))
        }

        setOpenDiv(true);
    };
    return (
            <div className="container d-flex flex-column justify-content-center gap-3">
                 <h1 className='text-center mt-4 pt-1'>{t("neverH")}</h1>
                <div style={{ margin: '2rem 0rem' }} className="d-flex justify-content-between">
                    <div className='d-flex align-items-center gap-3'>
                <p>{t("playW")}</p>
<Dropdown>
      <Dropdown.Toggle style={{backgroundColor:'#120651'}}  id="dropdown-basic">
        {questionType}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className="dropdown-item" onClick={handleFriendlyQA}>{t("playWFriends")}</Dropdown.Item >
    <Dropdown.Item className="dropdown-item" onClick={handleSexQA}>{t("playwith18")}</Dropdown.Item >
    <Dropdown.Item className="dropdown-item" onClick={handleBothQA}>{t("both")}</Dropdown.Item >
      </Dropdown.Menu>
    </Dropdown>
    </div>
                <SecondaryButton style={{
                width: '20rem',
                padding: '1rem 2rem',
                border: 'none',
                color:'#35212A',
                backgroundColor: 'rgb(255, 222, 243)'
         }} handleFunc={handleClick} buttonText='randomQuestionButton' />
                </div>
                {openDiv && (
                    <div
                        style={{
                            border: '3px solid #FFDEF3',  
                            color: 'white',
                            padding: '1.5rem 0rem'
                        }}
                    >
                        <p style={{ marginLeft: '2rem', color:'black' }}>{t("neverEver")} {t(randomItem)}</p>
                    </div>
                )}
                {sexQA && (
                    <ul className="d-flex flex-column gap-1">
                        {hotq.map((item, idx) => (
                            <li
                                style={{
                                    boxShadow: '2px 0px 1px #120651',
                                    color: 'white',
                                    padding: '1.5rem 0rem'
                                }}
                                key={idx}
                            >
                                <p style={{ color:'black' }}>
                                    {idx + 1} {t("neverEver")} <span style={{ color: 'black' }}>{t(item)}</span>
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
                {friendlyQA && (
                    <ul className="d-flex flex-column gap-1">
                        {friendlyq.map((item, idx) => (
                            <li
                                style={{
                                    boxShadow: '2px 0px 1px #120651',
                                    color: 'white',
                                    padding: '1.5rem 0rem'
                                }}
                                key={idx}
                            >
                                <p style={{color:'black' }}>
                                    {idx + 1} {t("neverEver")} <span style={{ color: 'black' }}>{t(item)}</span>
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
                {bothQA && (
                    <ul className="d-flex flex-column gap-1">
                        {bothq.map((item, idx) => (
                            <li
                                style={{
                                    boxShadow: '2px 0px 1px #120651',
                                    color: 'white',
                                    padding: '1.5rem 0rem'
                                }}
                                key={idx}
                            >
                                <p style={{color:'black' }}>
                                    {idx + 1} {t("neverEver")} <span style={{ color: 'black' }}>{t(item)}</span>
                                </p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
    );
};

let hotq = [
    "neverq-1",
"neverq-2",
"neverq-3",
"neverq-4",
"neverq-5",
"neverq-6",
"neverq-7",
"neverq-8",
"neverq-9",
"neverq-10",
"neverq-11",
"neverq-12",
"neverq-13",
"neverq-14",
"neverq-15",
"neverq-16",
"neverq-17",
"neverq-18",
"neverq-19",
"neverq-20",
"neverq-21",
"neverq-22",
"neverq-23",
"neverq-24",
"neverq-25",
"neverq-26",
"neverq-27",
"neverq-28",
"neverq-29",
"neverq-30",
"neverq-31",
"neverq-32",
"neverq-33",
"neverq-34",
"neverq-35",
"neverq-36",
"neverq-37",
];
let friendlyq = [
    "neverf-1",
"neverf-2",
"neverf-3",
"neverf-4",
"neverf-5",
"neverf-6",
"neverf-7",
"neverf-8",
"neverf-9",
"neverf-10",
"neverf-11",
"neverf-12",
"neverf-13",
"neverf-14",
"neverf-15",
"neverf-16",
"neverf-17",
"neverf-18",
"neverf-19",
"neverf-20",
"neverf-21",
"neverf-22",
"neverf-23",
"neverf-24",
"neverf-25",
"neverf-26",
"neverf-27",
"neverf-28",
"neverf-29",
"neverf-30",
"neverf-31",
"neverf-32",
"neverf-33",
"neverf-34",
"neverf-35",
"neverf-36",
"neverf-37",
"neverf-38",
"neverf-39",
];
let bothq = hotq.concat(friendlyq);
export default Never;
