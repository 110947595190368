import { Link } from 'react-router-dom';
const pages = [
    { title: 'Dice', link: 'dice' },
    { title: 'Card', link: 'card' },
    { title: 'Sex Challenges', link: 'hot' },
    { title: 'Never Ever Questions', link: 'never' },
    { title: 'Blog', link: 'blog' }
];
const Sidebar = (props) => {
    return (
        <div ref={props.refProp} className="sidebar">
            <ul className="d-flex flex-column gap-3">
                <li className="d-flex justify-content-between">
                    <Link to="/" style={{ color: 'black' }}>
                        Kamadice
                    </Link>
                    <button
                        style={{ border: 'none', backgroundColor: 'white' }}
                        onClick={() => props.setOpenSidebar(false)}
                    >
                        X
                    </button>
                </li>
                {pages.map((item, idx) => {
                    return (
                        <li key={idx}>
                            <Link
                                onClick={() => {
                                    props.setOpenSidebar(false);
                                }}
                                to={item.link}
                                style={{ color: 'black' }}
                            >
                                {item.title}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default Sidebar;
