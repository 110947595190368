import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './locales/en/common.json';
import translationTr from './locales/tr/common.json';
import translationFr from './locales/fr/common.json';
import translationGr from './locales/de/common.json';
import translationEs from './locales/es/common.json';
import translationPl from './locales/pl/common.json';
import translationIt from './locales/it/common.json';
import translationIn from './locales/in/common.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: { translation: translationEn },
            tr: { translation: translationTr },
            gr: { translation: translationGr },
            fr: { translation: translationFr },
            es: { translation: translationEs },
            in: { translation: translationIn },
            pl: { translation: translationPl },
            it: { translation: translationIt }
            // ru: { translation: translationRu },
            // ab: { translation: translationAb }
        },
        lng: 'en',

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
