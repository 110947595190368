// src/components/BlogCard.jsx
import React from 'react';

const BlogCard = ({ title, excerpt, image, onClick }) => {
    return (
        <div
            className="d-flex flex-column gap-2 m-2 shadow-lg p-4 mb-5 bg-body-tertiary rounded"
            style={{ cursor: 'pointer' }}
            onClick={onClick}
        >
            <img alt={title} variant="top" src={image} />
            <div className="">
                <h6 style={{ backgroundColor: 'rgb(255, 222, 243)' }} className="p-2">
                    {title}
                </h6>
                <div className="text-muted px-2">{excerpt}</div>
            </div>
        </div>
    );
};

export default BlogCard;
