// src/components/BlogDetail.jsx
import React from 'react';
import { Button } from 'reactstrap';

const BlogDetail = ({ title, content, image, onBack }) => {
    console.log(content, 'SLM');
    return (
        <div className="max-w-4xl mx-auto py-6 flex justify-content-center align-items-center">
            <h1
                style={{ backgroundColor: 'rgb(255, 222, 243)' }}
                className="text-4xl font-bold mb-4 p-2"
            >
                {title}
            </h1>
            <img height="400" className="w-full mb-4 " src={image} alt={title} />
            <div className="prose">
                <ul className="d-flex flex-column gap-1">
                    {content.map((item, idx) => (
                        <li
                            style={{
                                boxShadow: '2px 0px 1px #120651',
                                color: 'white',
                                padding: '1.5rem 0rem'
                            }}
                            key={idx}
                        >
                            <p style={{ color: 'black' }}>
                                <span
                                    style={{ backgroundColor: 'rgb(255, 222, 243)' }}
                                    className="mt-2 p-1"
                                >
                                    {idx + 1}
                                </span>
                                <span style={{ color: 'black' }}>{item}</span>
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default BlogDetail;
