import Modal from 'react-bootstrap/Modal';
const Modall = (props) => {
    const handlePermission = () => {
        localStorage.setItem('isBiggerThan18', 'yes');
        props.onHide();
    };
    const handlePermission2 = () => {
        localStorage.setItem('isBiggerThan18', 'false');
        props.onHide();
    };
    return (
        <>
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        18 yaşından büyük olduğunuzu onaylıyor musunuz?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center">
                        <button onClick={handlePermission}>Yes</button>
                        <button onClick={handlePermission2}>No</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default Modall;
