import dice from '../images/landing/1.png'
import cardd from '../images/landing/3.png'
import neverPic from '../images/landing/2.png'
import LandingCard from '../components/common/LandingCard';

const Home = () => {
    return (
        <div className="d-flex flex-column home-main">
            <div className=" d-flex flex-column align-items-center home-cards">
                <LandingCard
                container={true}
                title="diceLT"
                description="diceLP"
                slogan="diceLS"
                link={'dice'}
                button="diceLB"
                img={dice} />

<LandingCard
                align={true}
                title="neverLT"
                float='left'
                description="neverLP"
                slogan="neverLS"
                link={'never'}
                img={neverPic}
                button="neverLB" />
                <LandingCard 

container={true}
                title="cardLT"
                description="cardLP"
                slogan="cardLS"
                link={'card'}
                button="cardLB"
                img={cardd} />
                {/* <LandingCard 

align={true}
                title="hotLT"
                description="hotLP"
                slogan="hotLS"
                link={'hot'}
                button="hotLB" /> */}
            </div>
        </div>
    )
}
export default Home;