import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import pic from '../images/aboutPics/history.jpg'
import pic1 from '../images/aboutPics/alc.jpg'
import pic2 from '../images/aboutPics/fun.jpg'
import pic3 from '../images/aboutPics/sex.jpg'
import pic4 from '../images/aboutPics/draw.jpg'
import pic5 from '../images/aboutPics/love.jpg'

const About = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="container d-flex flex-column gap-3">
                <div
                    style={{
                        borderBottom: '1px solid black',
                        minWidth: '80%',
                        margin: 'auto',
                        padding: '2rem 0rem',
                        backgroundColor: 'rgb(255, 222, 243)'
                    }}
                    className="d-flex justify-content-center"
                >
                    <h1 style={{ fontSize: '1.2rem' }}>{t("dontPay")}</h1>
                </div>
                <div
                    style={{ minHeight: '400px', maxWidth: '80%', margin: 'auto' }}
                    className="d-flex gap-3"
                >
                    <div
                        className="d-flex flex-column "
                        style={{ backgroundColor: '#35212A', width: '100%', padding: '2rem' }}
                    >
                        <h5 style={{ color:'white'}}>{t("services")}</h5>
                        <ul className='d-flex flex-column gap-1 mt-2'>
                            <li>
                                <Link to='/dice'>Karmasutra Positions Dice</Link>
                            </li>
                            <li>
                                <Link to='/dice'>Kamasutra Places Dice</Link>
                            </li>
                            <li>
                                <Link to='/card'>Sex Positions Card</Link>
                            </li>
                            <li>
                                <Link to='/hot'>Sex Challenges Generator</Link>
                            </li>
                            <li>
                                <Link to='/never'>Never I Have Ever Question Generator</Link>
                            </li>
                        </ul>
                    </div>
                    <div style={{ backgroundColor: '#120651',color:'white', width: '100%', padding: '2rem' }}>
                        <h2 style={{ color:'white', paddingBottom:'1rem'}}>{t("aboutus")}</h2>
                        <p>{t("contactH")}
                            <br></br>
                            {t("contactH2")}
                        <br></br>
                        {t("contactH3")}
                        <br></br>
                        {t("contactH4")}
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        minWidth: '80%',
                        margin: 'auto',
                        minHeight: '125px',
                        borderTop:'2px solid black'
                    }}
                    className="d-flex gap-3 justify-content-between"
                >
                    <img alt='describing fun' style={{maxHeight:'125px'}} src={pic}></img>
                    <img alt='describing fun' style={{maxHeight:'125px'}} src={pic4}></img>
                    <img alt='describing fun' style={{maxHeight:'125px'}} src={pic1}></img>
                    <img alt='describing fun' style={{maxHeight:'125px'}} src={pic2}></img>
                    <img alt='describing fun' style={{maxHeight:'125px'}} src={pic5}></img>
                    <img alt='describing fun' style={{maxHeight:'125px'}} src={pic3}></img>
                </div>
            </div>
            <div></div>
        </div>
    );
};
export default About;
