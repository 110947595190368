import React from 'react';
import ReactDOM from 'react-dom/client';

import { createBrowserHistory } from 'history';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
const history = createBrowserHistory();


const root = ReactDOM.createRoot(document.getElementById('root'));
// if (root) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
	// const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	// if (path) {
	// 	history.replace(path);
	// }
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
// }


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
