import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

const LandingCard = (props) => {
    const { t } = useTranslation();
    return (
        <div style={{backgroundColor: props.color,
            minHeight:'50rem', 
            float:props.float}} 
            className={`landing-card justify-content-center
            container ${props.align ? 'fl-row' : null}`}>
                    <div className={`d-flex gap-4 flex-column`}>
                        <h2 style={{textTransform: 'uppercase', fontSize: '1.75rem'}}>{t(props.title)}</h2>
                        <h1 style={{color: '#120651', fontSize: '2.25rem'}}>{t(props.slogan)}</h1>
                        <p style={{fontStyle: 'italic', fontSize: '1.25rem'}}>
                        
                        {t(props.description)}
                        </p>
                        <button style={{padding:'.75rem 1rem', width:'17.5rem', border:'3.5px solid #FFDEF3', borderRadius:'.5rem', backgroundColor:'#FFDEF3'}}>
                            <Link style={{color:'black', backgroundColor:'#FFDEF3 !important', display: 'block'}} to={`/${props.link}`} >{t(props.button)}</Link>
                        </button>
                    </div>
                    <div  className={`d-flex justify-content-center align-items-center ${props.align ? 'float-left' : ''}`}>
                        {props.img ? <img alt={props.title} src={props.img} className='landing-img'></img> : null }
                    </div>
                </div>
    )
}

export default LandingCard;