import pic from '../images/spimg/69.png';
import pic1 from '../images/spimg/chairman.png';
import pic2 from '../images/spimg/corkscrew.png';
import pic3 from '../images/spimg/cowgirl.png';
import pic4 from '../images/spimg/doggy.png';
import pic5 from '../images/spimg/faceoff.png';
import pic6 from '../images/spimg/flatiron.png';
import pic7 from '../images/spimg/gwhiz.png';
import pic8 from '../images/spimg/leapfrog.png';
import pic9 from '../images/spimg/missionary.png';
import pic10 from '../images/spimg/pretzel.png';
import pic11 from '../images/spimg/scoopmeup.png';
import pic12 from '../images/spimg/snka.png';
import pic13 from '../images/spimg/standanddeliver.png';
import pic14 from '../images/spimg/tabletop.png';
import pic15 from '../images/spimg/upstanding.png';
import pic16 from '../images/spimg/valedictorian.png';
import pic17 from '../images/spimg/wheelbarrow.png';
import card from '../images/card2.png'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Card = () => {
    const { t } = useTranslation();
    const [isActive, setIsActive] = useState(false);
    
    const cards = [{
        name : 'The Chairman',
        img : pic1,
        style : {position: 'absolute', top: '105px', left: '40.5%', zIndex: '17'}
    },
    {
        name : 'Corkscrew',
        img : pic2,
        style : {position: 'absolute', top: '110px', left: '41%', zIndex: '16'}
    },
    {
        name : 'Cow Girl',
        img : pic3,
        style : {position: 'absolute', top: '115px', left: '41.5%', zIndex: '15'}
    },
    {
        name : 'Doggy',
        img : pic4,
        style : {position: 'absolute', top: '120px', left: '42%', zIndex: '14'}
    },
    {
        name : 'Face Off',
        img : pic5,
        style : {position: 'absolute', top: '125px', left: '42.5%', zIndex: '13'}
    },
    {
        name : 'Flatiron',
        img : pic6,
        style : {position: 'absolute', top: '130px', left: '43%', zIndex: '12'}
    },
    {
        name : 'G-Whiz',
        img : pic7,
        style : {position: 'absolute', top: '135px', left: '43.5%', zIndex: '11'}
    },
    {
        name : 'Leap Frog',
        img : pic8,
        style : {position: 'absolute', top: '140px', left: '44%', zIndex: '10'}
    },
    {
        name : 'Missionary',
        img : pic9,
        style : {position: 'absolute', top: '145px', left: '44.5%', zIndex: '9'}
    },
    {
        name : 'Pretzel Dip',
        img : pic10,
        style : {position: 'absolute', top: '150px', left: '45%', zIndex: '8'}
    },
    {
        name : 'Scoope Me Up',
        img : pic11,
        style : {position: 'absolute', top: '155px', left: '45.5%', zIndex: '7'}
    },

    {
        name : 'The Snake',
        img : pic12,
        style : {position: 'absolute', top: '160px', left: '46%', zIndex: '6'}
    },
    {
        name : 'Stand and Deliver',
        img : pic13,
        style : {position: 'absolute', top: '165px', left: '46.5%', zIndex: '5'}
    },
    {
        name : 'Table Top',
        img : pic14,
        style : {position: 'absolute', top: '170px', left: '47%', zIndex: '4'}
    },
    {
        name : 'Upstanding',
        img : pic15,
        style : {position: 'absolute', top: '175px', left: '47.5%', zIndex: '3'}
    },

    {
        name : 'Valedictorian',
        img : pic16,
        style : {position: 'absolute', top: '180px', left: '48%', zIndex: '2'}
    },
    {
        name : 'Wheelbarrow',
        img : pic17,
        style : {position: 'absolute', top: '185px', left: '48.5%', zIndex: '1'}
    },]
    const [randomItem, setRandomItem] = useState(card);
    function getRandomItem(arr) {
        const randomIndex = Math.floor(Math.random() * arr.length);

        const item = arr[randomIndex];
        return item.img;
    }
    const handleCard = () => {
        setIsActive((prev) => !prev);
        setTimeout(function () {
            setRandomItem(getRandomItem(cards))
        }, 900);
        
    };
    const backToCard = () => {
        setIsActive((prev) => !prev);
        setRandomItem(card)
    }
    return (
        <>
            <div className="cardParent d-flex flex-column justify-content-between align-items-center">
            <h1 className='cardTitle text-center mt-4 pt-1'>{t("cardH")}</h1>
            <div
                    onClick={isActive ? backToCard : handleCard}
                    style={{position: 'absolute', top: '100px', left: '40%', zIndex: '18'}}
                    className={isActive ? 'animation d-flex flex-column justify-content-center text-center gap-4 bcw bce' : 'card flex-column d-flex justify-content-center text-center gap-4 bcw'}
                >
                    <img className={isActive ? 'animationH' : 'cardH'} src={randomItem} ></img>
                </div>
                {cards.map((item,key) => (
                    <div
                    style={item.style}
                    className={'card d-flex flex-column justify-content-center text-center gap-4 bcw'}
                >
                    <img src={card} ></img>
                </div>
                ))}
            </div>
        </>
    );
};
export default Card;
